import React from "react"
import * as styles from "./layout.module.scss"

export const Center = ({ children, ...otherProps }) => (
  <div className={styles.center} {...otherProps}>
    {children}
  </div>
)

export const Row = ({ children, ...otherProps }) => (
  <div className={styles.row} {...otherProps}>
    {children}
  </div>
)
