import * as Sentry from "@sentry/browser"
import axios from "axios"

export async function createStripeDonation(euros, retry_count = 5) {
  const cents = Math.round(euros * 100)

  const response = await fetch("/api/create-stripe-donation", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      cents,
    }),
  })

  if (!response.ok && retry_count > 1) {
    Sentry.captureEvent({
      message: "create-stripe-donation API call failed",
      level: "warning",
      extra: {
        response,
        retry_count,
      },
    })
    return createStripeDonation(euros, retry_count - 1)
  }

  return response.json()
}

export async function getSchoolData() {
  const response = await axios.get("/api/schools")
  return response.data
}

export async function submitSchoolSignUp({
  firstname,
  lastname,
  schoolName,
  role,
  phoneNumber,
  email,
}) {
  const response = await axios.post(
    "/api/process-sign-up",
    {
      firstname,
      lastname,
      schoolName,
      role,
      phoneNumber,
      email,
    },
    {
      validateStatus: status =>
        (status >= 200 && status < 300) || status === 400,
    }
  )
  return response.data
}
